import { HttpStatusCodeEnum, ZepQuizApiError } from '@zep/api/httpClient';
import Axios, { AxiosError } from 'axios';

const isApiErrorInErrorCodes = (error: unknown, httpCodes: number[]) => {
  if (error instanceof ZepQuizApiError) {
    return httpCodes.includes(error.httpStatus);
  }

  if (Axios.isAxiosError(error)) {
    return httpCodes.includes(error.response?.status || 200);
  }
};

export const isIgnoreError = (_error: unknown) => {
  return isApiErrorInErrorCodes(_error, [
    HttpStatusCodeEnum.UNAUTHORIZED_401,
    HttpStatusCodeEnum.FORBIDDEN_403,
  ]);
};

// 404 에러, 400 에러는 무시
export const isIgnoreRetryError = (_error: unknown) => {
  return isApiErrorInErrorCodes(_error, [
    HttpStatusCodeEnum.UNAUTHORIZED_401,
    HttpStatusCodeEnum.FORBIDDEN_403,
    HttpStatusCodeEnum.BAD_REQUEST_400,
    HttpStatusCodeEnum.NOT_FOUND_404,
    HttpStatusCodeEnum.INTERNAL_SERVER_ERROR_500,
  ]);
};

export const setApiErrorLogger = (error: AxiosError) => {
  if (isIgnoreError(error)) return;

  if (!error.response && error.message === 'Network Error') {
    console.error('네트워크 연결이 불안정하거나 서버에 접근할 수 없습니다.', {
      ...error,
    });
  } else if (!error.response) {
    // 네트워크 문제 (서버 응답 없음)
    console.error('네트워크 오류:', {
      ...error,
    });
  } else if (error.code === 'ECONNABORTED') {
    console.error('요청이 타임아웃되었습니다.', { ...error });
  }
};

export const LONG_REQUEST_TIMEOUT = 5 * 60 * 1000;
